import { createContext, useState } from 'react';

export const DrawerContext = createContext({
	isOpen: false,
	toggleDrawer: () => {},
});

const DrawerContextProvider = props => {
	const [open, setOpen] = useState(false);

	const toggleDrawerHandler = () => {
		setOpen(prevState => !prevState);
	};

	return (
		<DrawerContext.Provider
			value={{
				isOpen: open,
				toggleDrawer: toggleDrawerHandler,
			}}
		>
			{props.children}
		</DrawerContext.Provider>
	);
};

export default DrawerContextProvider;
