import ScrollToTop from 'react-scroll-up';
import { AiOutlineArrowUp } from 'react-icons/ai';
import styled from 'styled-components/macro';

const UpIcon = styled.div`
	background-color: #000;
	color: #fff;
	border-radius: 25%;
	border: 1px solid #f04668;
	width: 35px;
	height: 35px;
	transition: all 0.4s;

	&:hover {
		background-color: #fff;
		color: #000;
	}
`;

const ScrollToTopIcon = () => {
	return (
		<ScrollToTop showUnder={160} duration={600}>
			<UpIcon className="d-flex justify-content-center align-items-center">
				<AiOutlineArrowUp size="1.5em" />
			</UpIcon>
		</ScrollToTop>
	);
};

export default ScrollToTopIcon;
