import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Spinner from './components/Spinner/Spinner';
import './i18n';
import DrawerContextProvider from './context/ui-context';

ReactDOM.render(
	<Suspense fallback={<Spinner />}>
		<DrawerContextProvider>
			<App />
		</DrawerContextProvider>
	</Suspense>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
