import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import './App.css';

import AppRouter from './routes/routes';

const App = () => {
	// change site direcation automatically based on lang
	const { i18n } = useTranslation();
	document.body.dir = i18n.dir();
	document.documentElement.lang = i18n.language;

	return (
		<Fragment>
			<AppRouter />
		</Fragment>
	);
};

export default App;
