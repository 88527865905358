import { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../components/Spinner/Spinner';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import ScrollToTopIcon from '../components/ScrollToTop/ScrollToTopIcon';

const Home = lazy(() => import('../pages/Home/Home'));
const About = lazy(() => import('../pages/About/About'));
const WebDevelopment = lazy(() => import('../pages/Web/WebDevelopment'));
const Iot = lazy(() => import('../pages/IOT/Iot'));
const Mobile = lazy(() => import('../pages/Mobile/Mobile'));
const Business = lazy(() => import('../pages/Business/Business'));
const ERP = lazy(() => import('../pages/ERP/ERP'));
const Career = lazy(() => import('../pages/Career/Career'));
const ContactUs = lazy(() => import('../pages/ContactUs/ContactUs'));

const AppRouter = () => {
	return (
		<Suspense fallback={<Spinner />}>
			<BrowserRouter>
				<ScrollToTop />
				<Switch>
					<Route path="/" component={Home} exact />
					<Route path="/web-development" component={WebDevelopment} />
					<Route path="/iot" component={Iot} />
					<Route path="/about" component={About} />
					<Route path="/mobile-application" component={Mobile} />
					<Route path="/business-consultation" component={Business} />
					<Route path="/enterprise-software" component={ERP} />
					<Route path="/jobs" component={Career} />
					<Route path="/contact-us" component={ContactUs} />
					<Redirect to="/" />
				</Switch>
				<ScrollToTopIcon />
			</BrowserRouter>
		</Suspense>
	);
};

export default AppRouter;
