import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// default lang
		supportedLngs: ['ar', 'en'],
		fallbackLng: 'en',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: ['path', 'cookie', 'htmlTag'],
			caches: ['cookie'],
		},
		backend: {
			loadPath: '/locales/{{lng}}/translation.json',
		},
		react: {
			wait: true,
		},
	});

export default i18n;
